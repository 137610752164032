/* Footer.css */

.footer-container {
  background-color: #378042;
  color: white;
  padding: 20px;
  text-align: center;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-section {
  flex: 1;
  margin: 10px;

}

.footer-address {
  margin-bottom: 10px;
}

.footer-contact {
  margin-bottom: 10px;
}

.footer-link {
  text-decoration: none;
  color: white;
}

.footer-icon {
  width: 30px;
  height: 30px;
}

.footer-sitemap {
  display: flex;
  flex-direction: column;
}

.footer-contact a,
.footer-sitemap a {
  color: white;
  text-decoration: none;
  position: relative;
  padding: 5px 0;
  transition: color 0.3s ease;
}

.footer-sitemap a::before {
  content: '';
  position: absolute;
  width: 10%;
  height: 2px;
  bottom: 0;
  left: 45%;
  background-color: white;
  visibility: hidden;
  transform: scaleX(0);
  transition: all 0.3s ease-in-out;
}

.footer-sitemap a:hover::before {
  visibility: visible;
  transform: scaleX(1);
}