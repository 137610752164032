.careers-details {
    text-align: center;
  }

  .careers-container {
    max-width: 100%;
    margin: auto;
    text-align: center;
    z-index: 0;
  }

  .careers-details > div {
    margin-bottom: 80px; 
  }
  
  .image-container {
    width: 100%; 
    height: 400px; 
    overflow: hidden; 
  }
  
  .careers-image {
    width: 100%; 
    height: 100%;
    object-fit: cover; 
  }

  
  
  .careers-title {
    position: absolute; 
    top: 35%; 
    left: 30%; 
    transform: translate(-50%, -50%);
    font-size: 50px; 
    font-weight: bold; 
    color: #fff; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
  }

  .careers-header {
    font-size: 25px;
  }
  
  .careers-detail-body{
    margin:auto;
    max-width:700px;
    justify-content: center;
    align-items: center;
  }

  .careers-description {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
  }

  .careers-learnmore{
    margin-bottom: 50px;
  }
  