.industry-details {
    text-align: center;

  }

  .industry-container {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;

  }

  .industry-details > div {

    margin-bottom: 80px; 
  }
  
  .image-container {
    position: relative; 
    width: 100%; 
    height: 400px; 
    overflow: hidden; 
  }
  
  .industry-image {
    width: 100%; 
    height: 100%;
    object-fit: cover; 
    border-radius: 8px;
  }

  .image-container::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5));
  }
  
  .industry-title {
    position: absolute; 
    top: 25%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    font-size: 24px; 
    font-weight: bold; 
    color: #fff; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
  }

  .industry-title-header {
    position: absolute; 
    top: 55%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    font-size: 15px; 
    font-weight: bold; 
    color: #fff; 
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); 
  }
  
  .industry-detail-body{
    margin:auto;
    max-width:800px;
    justify-content: center;
    align-items: center;
  }

  .industry-description {
    font-size: 16px;
    line-height: 1.5;
    color: #666;
  }
  