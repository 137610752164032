.home-container {
    background-color: #f8f9fa;
    text-align: center;
  }

.home-container > div{
    margin-bottom: 40px;
}

.home-content > div {
    margin-bottom: 40px;
}

  .carousel-container {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;
  }
  
  .home-content {
    max-width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 20px;

  }

  .description-container {

    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    
  }

  .margins {
    margin-top: 40px;
    margin-bottom: 40px;

  }
  
  .home-title {
    color: #333;
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  .home-description {
    color: #666;
    font-size: 1.2rem;
    line-height: 1.6;
  }
  
  .industry-focus-container {
    margin-top: 60px;
    text-align: center;
  }
  
  .industry-focus-title {
    color: #333;
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  /* Styling for the call-to-action section */
.calltoaction {
    margin-top: 30px;
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    text-align: center;
  }
  
 
  .calltoaction h1 {
    font-size: 1.5rem;
    color: #333;
    margin-bottom: 10px;
  }
  

  .cta-button {
    margin-top: 25px;
    padding: 12px 24px;
    background-color: #48ad57;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button:hover {
    background-color: #378042;
  }

  .chemical-list {
    margin: auto;
    width: 500px;
    list-style-type: none;
    padding: 0;
    display: grid;
    grid-template-columns: repeat(2, 1fr); 
    justify-content: center;
  }
  
  .chemical-list li {
    background-color: #378042;
    color: #fff;
    font-size: 1.2rem;
    padding: 10px 20px;
    border-radius: 5%;
    margin:  10px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 150px;
    height: 30px;

    box-shadow: 8px 8px 16px rgba(0, 0, 0, 0.397);
  }