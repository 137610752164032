.contact-container{
    max-width: 100%;
    margin:auto;
}

.contact-info{
    padding-top: 8%;
    width: 100%;
    text-align: center;
}


.contact-body{
    margin:auto;
    padding:30px;
    max-width: 800px;
    justify-self: center;
}

form >div {
    margin-bottom:10px;
}

.contact-header{
    width: 100%;
    height: 400px;
}

.contactus-image{
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.contact-form{
    margin: 10px;
    background-color: rgba(37, 80, 42, 0.5);;
    padding: 50px;
    border-radius: 5%;
}

.contact-field{
    align-items:center;
    margin:30px;    
}

.contact-label{
    text-align: left;
    font-weight: bold;
}


.contact-input{
    border: none; 
    border:solid 2px gray;
    width: 100%;
    height:30px;
}

.contact-message{
    border: none; 
    border:solid 2px gray;
    width: 100%;
    height:100px;
}

.form-button button{
    background-color: #48ad57;
    color: white;
    border: none;
    text-align: center;
    width: 100px;
    height: 30px;
    cursor: pointer
}

.form-button button:hover{
    background-color: #378042;
}