.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
    background-color: white;
    color: #378042;
  }
  
  .navbar-logo {
    font-size: 1.5em;
  }
  
  .navbar-links {
    display: flex;
    gap: 15px;
  }
  
  .navbar-links a {
    color: #378042;
    text-decoration: none;
    position: relative;
    padding: 5px 0;
    transition: color 0.3s ease;
  }
  
  .navbar-links a::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: #378042;
    visibility: hidden;
    transform: scaleX(0);
    transition: all 0.3s ease-in-out;
  }
  
  .navbar-links a:hover::before {
    visibility: visible;
    transform: scaleX(1);
  }
  
  .navbar-toggle {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .navbar-toggle span {
    background: #378042;
    height: 3px;
    margin: 4px;
    width: 25px;
  }
  
  .navbar-menu {
    display: flex;
  }
  
  @media (max-width: 768px) {
    .navbar-links {
      display: none;
      flex-direction: column;
      width: 100%;
      background-color: white;
      position: absolute;
      top: 80px;
      left: 0;
      text-align: center;
      z-index: 1;
    }

  
    .navbar-links.active {
      display: flex;
    }
  
    .navbar-toggle {
      display: flex;
    }
  
    .navbar-menu {
      display: none;
    }
  }