.industry-focus-container {
    text-align: center;
    margin-bottom: 40px;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); 
    gap: 20px; 
    justify-content: center;
  }
  
  .industry-item {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 90%; 
    height: 200px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    cursor: pointer;
  }
  
  .industry-item:hover {
    transform: scale(1.05);
  }
  
  .industry-item::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;

  }
  
  .industry-text {
    position: absolute;
    bottom: 30%;
    left: 0;
    right: 0;
    color: #fff;
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    padding: 10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5)
  }
  